<template>
    <div>
        <cd-key-search-bar-component
            @search="get_cd_key_index"
        ></cd-key-search-bar-component>
        <el-card class="box_card" v-loading.fullscreen.lock="loading">
            <el-table
                :data="table_data"
                style="width: 100%"
                border
                stripe
                highlight-current-row
            >
                <el-table-column
                    align="center"
                    type="index"
                    min-width="10px">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="state"
                    label="到账状态"
                    min-width="90px"
                >
                    <template slot-scope="scope">
                        <el-tag size="small" type="info" v-if="scope.row.state === 0">未到账</el-tag>
                        <el-tag size="small" type="success" v-else-if="scope.row.state === 1">已到账</el-tag>
                        <el-tag size="small" type="warning" v-else-if="scope.row.state === 2">秒到账</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="key"
                    label="抽奖码"
                    min-width="190px"
                    >
                    <template slot-scope="scope">
                        <span>{{ scope.row.key }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="item"
                    label="奖品"
                    min-width="170px"
                />
                <el-table-column
                    align="center"
                    prop="create_staff_name"
                    label="使用人"
                    min-width="150px">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="create_time"
                    label="抽奖时间"
                    min-width="160px">
                </el-table-column>
            </el-table>
        </el-card>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="total"
            @search="get_cd_key_index"
        ></common-page-component>
    </div>
</template>

<script>
import { CdKeyIndexRequest } from '@/network/query/CdKey.js'

import CdKeySearchBarComponent from '@/components/query/CdKey/CdKeySearchBarComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'

export default {
    name: 'CdKeyView',
    data() {
        return {
            loading: true,
            cond: {
                state: '',
                key:'',
                staff_name: '',
                page: 1,
                limit: 10
            },
            total: 0,
            table_data: []
        }
    },
    computed: {},
    methods: {
        get_cd_key_index(param = {}) {

            this.loading = true
            this.cond.state = param.state ?? this.cond.state
            this.cond.key = param.key ?? this.cond.key
            this.cond.staff_name = param.staff_name ?? this.cond.staff_name
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            CdKeyIndexRequest(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.table_data = s.result.data
                        this.total = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })

            //滚动条回顶部
            this.$nextTick(() => {
                document.getElementById('el_main').scrollTop = 0;
            })
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_cd_key_index()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CdKeySearchBarComponent,
        CommonPageComponent
    },
    watch: {},
}
</script>

<style lang='less' scoped>
.box-card .el-form-item {
    margin-bottom: 3px;
}
</style>