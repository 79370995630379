<template>
    <el-card class="box-card" sytle="padding: 5px;">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item  label="到账状态" prop="state">
                <el-select
                    v-model="form.state"
                    filterable
                    clearable
                    placeholder=""
                    class="state_c"
                >
                    <el-option
                        v-for="item in AllState"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="抽奖码" prop="key">
                <el-input
                    v-model="form.key"
                    placeholder="抽奖码搜索"
                    @keyup.enter.native="search()"
                    clearable
                    class="key_c"
                ></el-input>
            </el-form-item>
            <el-form-item label="使用人" prop="staff_name">
                <el-input
                    v-model="form.staff_name"
                    placeholder="使用人搜索"
                    @keyup.enter.native="search()"
                    clearable
                    class="staff_name_c"
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')">重置</el-button>
            </el-form-item>
        </el-form>
    </el-card>
</template>

<script>
export default {
    name: 'CdKeySearchBarComponent',
    data() {
        return {
            form: {
                state: '',
                key: '',
                staff_name: '',
                page: 1
            },
            AllState: [
                {id:0,name:'未到账'},
                {id:1,name:'已到账'},
                {id:2,name:'秒到账'}
            ]
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        }
    },
    filters: {},
    props: {},
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style lang='less' scoped>
.box-card .el-form-item {
    margin-bottom: 3px;
}
.state_c,.type_c {
    width: 120px;
}
.key_c {
    width: 260px;
}
.staff_name_c {
    width: 200px;
}
</style>